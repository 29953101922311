import Aos from 'aos'
import React from 'react'
import { useTranslation } from 'react-i18next';

export const Footer = ({token ,setUserData , userData,  setToken ,phoneP, phoneD = '' , email=''}) => {
	const {t} = useTranslation('home');
	Aos.init();
  return (
<>


			<footer class="footer">
			{/* https://doxx.page.link/Xk57 */}
				
                <div class="footer-bottom">
					<div class="container-fluid">
					
						<div class="copyright">
							<div class="row">
								<div class="col-md-6 col-lg-6">
									<div class="copyright-text">
										{/* <p class="mb-0">&copy; 2023 {t('All rights reserved for Doxx')}.</p> */}
									</div>
								</div>
								<div class="col-md-6 col-lg-6">
								
									<div class="copyright-menu">
										<ul class="policy-menu">
											{/* <li><a href="/data/TermsAndConditions.pdf">{t('Terms and Conditions')}</a></li>
											<li><a href="/data/PrivacyAndPolicy.pdf">{t('Policy')}</a></li> */}
										</ul>
									</div>
									
								</div>
							</div>
						</div>
						
					</div>
				</div>
				
			</footer>

</>  )
}
