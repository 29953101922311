import React, { Suspense, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2';

import { Spin } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import i18n from '../../i18n';
import * as CONSTANTS from '../../CONSTANTS';
import { useTranslation } from 'react-i18next';

async function loginUser(credentials) {
    try {
    let response = await  fetch(CONSTANTS.DEFAULT_URL+'auth/forgot-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
    let json = await response.json();
            return { success: true, data: json };

         

return {success:false};


} catch (error) {
    return { success: false };
  }
}
export const ResetPassword = ({ setToken , token}) => {
    const {t} = useTranslation('home');
    const [load , setLoading] = useState(false);
const navigate = useNavigate();
    const [message , setMessage] = useState('');
    const [identifier, setUserName] = useState('');
    const params = useParams();

    const handleSubmit = async e => {
        e.preventDefault();
        setMessage('');

        setLoading(true);
        const token = await loginUser({
            identifier:'+'+identifier,
          type:params.type,
          applicationLanguage:i18n.language

        });
        console.log(token);
if(token.data.error){
    if(token.data.error.name === 'ValidationError'){
        setLoading(false);

       setMessage(token.data.error.message);
       return false;
    }
    if(token.data.error.name === 'ApplicationError'){
        setLoading(false);

       setMessage(token.data.error.message);
       return false;
    }
    if(token.data.error.name === 'ForbiddenError'){
        setLoading(false);

       setMessage(token.data.error.message);

       return false;
    }
}

        if(token.data.ok === true){
            setLoading(false);
            navigate('/'+i18n.language+'/login/'+params.type);


        }
      }

		useEffect(() => {

			if(token != null ){
				setLoading(true);
				const headers = {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+token
				  }
                axios.get(CONSTANTS.DEFAULT_URL+'users/me' , {
					headers: headers
				  })
        .then(function (response) {
          // handle success
             
		  setLoading(false);

        })
        .catch(function (error) {
          // handle error
		  console.log(error.response);
        //  setToken(null);
		// if(error.response.status === 403){
		// 	navigate('/login');


		// }
          console.log(error);
        })
        .finally(function () {
			setLoading(false);
        });
	}else{
		setLoading(false);

	}

	}, [params]);

  return (
    <Suspense>
        	<Spin spinning={load}>

    <div class="content">
    <div class="container-fluid">

        <div class="row">
            <div class="col-md-8 offset-md-2">

                <div class="account-content">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-md-7 col-lg-6 login-left">
                            <img src="/assets/img/login-banner.png" class="img-fluid" alt="Doxx Login"/>
                        </div>
                        <div class="col-md-12 col-lg-6 login-right">
                            <div class="login-header">
                            <h3>{t('Reset Password')} {params.type === 'patient' ? (<><Link  to={"/"+i18n.language+"/reset-password/doctor"}>{t('areYouDoctor')}</Link></>) : (<><Link  to={"/"+i18n.language+"/reset-password/patient"}>{t('areYouPatient')}</Link></> )} </h3>

                                <p className='text-danger'>{message}</p>
                            </div>
                            <Spin spinning={load} >
                            <form onSubmit={handleSubmit}>
                                <div class="form-group form-focus">
                                <PhoneInput
                                required

  country={"eg"}
  inputClass={"form-control floating"}
  inputStyle={{width:"100%",paddingBottom:"20px"}}
  preferredCountries={['ae','ksa','alg','bh','eg','iq']}
  value={identifier}
  onChange={(e) => setUserName(e)}
/>



                                </div>
                             
                               
                                <button class="btn btn-primary w-100 btn-lg login-btn" type="submit">{t('Reset Password')}</button>

                            </form>
                            </Spin>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

</div>
</Spin>
    </Suspense>

  )
}
ResetPassword.propTypes = {
    setToken: PropTypes.func.isRequired
  }
