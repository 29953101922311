import React from "react";
import classnames from "classnames";
import styled from "styled-components";
import Alert from "react-feather/dist/icons/alert-triangle";

const Fallback = React.memo(styled(({ className , error }) => (
  
  <div className={classnames("error-boundary", className)}>
    <Alert color="blue" size={100} />
    <span>WE CANT CONNECT TO DOXX SERVERS NOW</span>
    <p>{error}</p>
  </div>
))`
  display: flex;
  flex-flow: row wrap;
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;

  span {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 1rem;
    font-weight: 500;
    font-size: 1.25rem;
    color: #333;
  }
`);





class ErrorBoundary extends React.Component {
  state = {
    errorMessage: '',
  };

  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() };
  }

  componentDidCatch(error, info) {
    this.logErrorToServices(error.toString(), info.componentStack);
  }

  // A fake logging service.
  logErrorToServices = console.log;

  render() {
    if (this.state.errorMessage) {
      return  (<><Fallback error={this.state.errorMessage} /></>) ;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;