import { Select, Skeleton,TimePicker, Upload } from 'antd'
import React, { Suspense, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import i18n from '../../i18n'
import * as CONSTANTS from '../../CONSTANTS'
import axios from 'axios'
import { Button, message,Modal } from 'antd';
import { useTranslation } from 'react-i18next'
import { check_auth } from '../../helper'


import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

export const ChangePassword = ({token}) => {
    const chagnePassword = async () => {
		const userData = await check_auth(token);


		let response = axios.put(CONSTANTS.DEFAULT_URL + 'users/' + userData.data.id, {
			"password": newPassword,
            "oldPassword":oldPassword
			


		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token

			}
		}).then((response) => {
			console.log(response);
message.success(t('Updated Successfully'));
setNewPassword('');
setOldPassword('');
setConfirmPassword('');
		}).catch((error) => {
			console.log(error.response.data.error);
			// error.response.data.error.details.errors.forEach(element => {
            message.error(error.response.data.error.message);

			// });


		});






	}


    const onChange = (time) => {
        setValue(time);
      };
    const [value, setValue] = useState(null);

    const {t} = useTranslation('home');


  
    
 
//http://3.70.182.78:1337/api/clinics?filters[$and][0][doctors][licenseNumber][$eq]=16881
const [load , setLoading] = useState(true);

const [userData , setData] = useState();
const [title , setTitle] = useState(null);
const [messageApi, contextHolder] = message.useMessage();
const [date , setDate] = useState(null);
const [patient , setPatient] = useState(null);
const [oldPassword , setOldPassword] = useState(null);
const [newPassword , setNewPassword] = useState(null);
const [confirmPassword , setConfirmPassword] = useState(null);


const navigate = useNavigate();




useEffect(() => {
 
setLoading(false);
    if(token != null ){
        setLoading(true);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token
          }
        axios.get(CONSTANTS.DEFAULT_URL+'users/me' , {
            headers: headers
          })
.then(function (response) {
 
  setLoading(true);
       if(response.status === 200){
        setData(response.data);

        setLoading(false);

       }else{
        navigate('/login');
       }
    

})
.catch(function (error) {
            navigate('/login');


setLoading(true);

if(error.response.status === 403){
            navigate('/login');



}
  console.log(error);
})
// .finally(function () {
//     setLoading(false);
// });
}else{
        navigate('/login');


setLoading(true);

}


}, []);



  return (
    <div className='container'>
    <Suspense>
        {load === false ? (<><div class="breadcrumb-bar">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-md-12 col-12">
                        <nav aria-label="breadcrumb" class="page-breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/">{t('Home')}</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{t('Change Password')}</li>
                            </ol>
                        </nav>
                        <h2 class="breadcrumb-title">{t('Change Password')}</h2>
                    </div>
                </div>
            </div>
        </div>
            <div class="content">
                <div class="container-fluid">
                    <div class="row">


                        <div class="col-md-7 col-lg-8 col-xl-9">
                            <div class="card">
                                <div class="card-body">
                                <form>
                                                <div class="row form-row">
                                               
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>{t('Old Password')}</label>
                                                            <input type="password" class="form-control" onChange={(e)=>{setOldPassword(e.target.value)}} value={oldPassword} />
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>{t('New Password')}</label>
                                                            <input type="password" class="form-control" onChange={(e)=>{setNewPassword(e.target.value)}} value={newPassword} />
                                                        </div>
                                                    </div>


                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>{t('Confirm Password')}</label>
                                                            <input type="password" class="form-control" onChange={(e)=>{setConfirmPassword(e.target.value)}} value={confirmPassword} />
                                                        </div>
                                                    </div>
                                                 
                                           
       

                   </div>
{/* a                                                                                           sync function registerAddress(token ,note , patient , street , lat ,lng , defaultAddress = false,tag  ,  city  ,zipCode ) { */}


                                                <div class="submit-section">
                                                    <button type="button" onClick={()=>{chagnePassword()}} class="btn btn-primary submit-btn">{t('Save Changes')}</button>
                                                </div>
                                            </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div></>) : (<Skeleton active />)}

    </Suspense>
</div>
  )
}
